import React, { createContext, useEffect, useState } from "react";

const PageContext = createContext([]);

function PageProvider(props) {
  const [pages, setPages] = useState([]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    async function run() {
      try {
        const response = await fetch("//u13821039.se/page.php");
        setPages(await response.json());
      } catch (error) {
        console.log(error);
      }

      try {
        const response = await fetch("//u13821039.se/page.php?images=1");
        setImages(await response.json());
      } catch (error) {
        console.log(error);
      }
    }
    run();
  }, []);

  return (
    <PageContext.Provider value={{ pages, images }}>
      {props.children}
    </PageContext.Provider>
  );
}

export { PageContext };
export default PageProvider;
