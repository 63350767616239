export default [
  "beforeChange",
  "afterChange",
  "imageLoadComplete",
  "resize",
  "gettingData",
  "mouseUsed",
  "initialZoomIn",
  "initialZoomInEnd",
  "initialZoomOut",
  "initialZoomOutEnd",
  "parseVerticalMargin",
  "close",
  "unbindEvents",
  "destroy",
  "updateScrollOffset",
  "preventDragEvent",
  "shareLinkClick"
];
