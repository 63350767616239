import React, { useContext } from "react";
import MenuItem from "../components/MenuItem";
import { PageContext } from "../context/PageProvider";
import styles from "./Home.module.css";

function Home() {
  document.body.classList = "start-page";

  const { pages } = useContext(PageContext);

  const menuItems = pages.map(page => {
    return (
      <MenuItem key={page.pageID} link={page.urlPath}>
        {page.navTitle}
      </MenuItem>
    );
  });

  const reloadStyle = {
    position: "absolute",
    bottom: 0,
    right: 0,
    zIndex: 999,
    width: "400px",
    height: "250px"
  };

  return (
    <>
      <div style={reloadStyle} onClick={() => window.location.reload(true)} />
      <div className={styles.sqbr} />
      <div className={styles.sqtl}>{menuItems}</div>
      <h1 className={styles.heading}>
        Välkommen
        <br />
        till Överhogdal
      </h1>
    </>
  );
}

export default Home;
