import React from "react";
import { Link } from "react-router-dom";
import styles from "./MenuItem.module.css";

function MenuItem(props) {
  return (
    <Link to={props.link} className={styles.item}>
      <span>{props.children}</span>
    </Link>
  );
}

export default MenuItem;
