import React, { useContext } from "react";
import MenuItem from "../components/MenuItem";
import { PageContext } from "../context/PageProvider";
import styles from "./Home.module.css";
import { Link } from "react-router-dom";

function SubPage(props) {
  document.body.classList = "start-page";

  const url = props.location.pathname;
  const { pages } = useContext(PageContext);

  const page = pages.filter(page => page.urlPath === url)[0];

  const menuItems = page.subpages.map(_page => {
    return (
      <MenuItem key={_page.pageID} link={_page.urlPath}>
        {_page.navTitle}
      </MenuItem>
    );
  });

  return (
    <>
      <Link to="/" className={styles.sqmenu}>
          <span>‹- Meny</span>
      </Link>
      <div className={styles.sqtl}>
      </div>
      <div className={styles.sqbr} />
      <div className={styles.sqtl}>{menuItems}</div>
      <h1 className={styles.heading}>
        {page.pageName}
      </h1>
    </>
  );
}

export default SubPage;
