import React, { useContext, useEffect, Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import { PageContext } from "./context/PageProvider";
import Home from "./pages/Home";
import Info from "./pages/Info";
import SubPage from "./pages/SubPage";

function App() {
  const { pages, images } = useContext(PageContext);
  const rootPages = pages.filter(page => page.subpages.length === 0).map(page => {
    return <Route key={page.pageID} path={page.urlPath} component={Info} />;
  });

  const subPages = pages.filter(page => page.subpages.length > 0).map(page => {
    return (
      <Fragment key={page.pageID}>
        <Route path={page.urlPath} exact component={SubPage} />
        <Route path={page.urlPath + ':page'} component={Info} />
      </Fragment>
    );
  });

  useEffect(() => {
    const cacheImages = [];
    images.forEach((image, i) => {
      cacheImages[i] = new Image();
      cacheImages[i].src = image;
    });
  }, [images]);

  return (
    <div className="App">
      <Route
        render={({ location }) => (
          <Switch location={location}>
            <Route path="/" exact component={Home} />
            {rootPages}
            {subPages}
          </Switch>
        )}
      />
    </div>
  );
}

export default App;
