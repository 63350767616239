import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "../components/PhotoSwipe/default-skin/default-skin.css";
import "../components/PhotoSwipe/photoswipe.css";
import PhotoSwipeGallery from "../components/PhotoSwipe/PhotoSwipeGallery";
import { PageContext } from "../context/PageProvider";
import styles from "./Info.module.css";

function Info(props) {
  document.body.classList = "info-page";

  const url = props.location.pathname;
  const { pages } = useContext(PageContext);

  let page = pages.filter(page => page.urlPath === url)[0];
  let rootUrl = "/";

  if (props.match.path.includes(':')) {
    rootUrl = url.slice(0, -1);
    rootUrl = rootUrl.substr(0, rootUrl.lastIndexOf("/") + 1);

    page = pages.filter(page => page.urlPath === rootUrl)[0];
    page = page.subpages.filter(subpage => subpage.urlPath === url)[0];
  }

  const gallery = page.gallery.map(item => {
    return {
      src: item.src,
      w: item.imageWidth,
      h: item.imageHeight,
      title: item.title
    };
  });

  function getThumbnailContent(item) {
    return <img src={item.src} width={90} alt="" />;
  }

  let displayGallery;
  if (gallery && gallery.length) {
    displayGallery = (
      <PhotoSwipeGallery
        items={gallery}
        options={{
          bgOpacity: 0.9,
          showHideOpacity: true,
          showAnimationDuration: 0,
          hideAnimationDuration: 0,
          maxSpreadZoom: 0,
          pinchToClose: false,
          mouseUsed: true
        }}
        thumbnailContent={getThumbnailContent}
        shareLinkClick={false}
      />
    );
  }

  return (
    <>
      <div className={styles.sqtl}>
        <Link to={rootUrl} className={styles.sqmenu}>
          <span>‹- Meny</span>
        </Link>
      </div>
      <section className="bodybgimg" />
      <div className={styles.bodycontent}>
        <div className={styles.pagedata}>
          <h1>{page.pageName}</h1>
          <div dangerouslySetInnerHTML={{ __html: page.pageContent }} />
        </div>

        <br />
        <br />
        {displayGallery ? (
          <div className="gallery">
            <h2>Bildgalleri</h2>
            {displayGallery}
          </div>
        ) : (
          ""
        )}
        <br />
        <br />
        <br />
        <br />
      </div>
    </>
  );
}

export default Info;
